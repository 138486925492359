import { Routes, Route, Navigate } from "react-router-dom";
import En from "./Components/En";
import Privacy from "./Components/Privacy";
import HomePage from "./Components/HomePage";
import OfficeAssistant from "./Components/OA/OfficeAssistant";
import { LandingPage } from "./Components/Products/ProductsPage";
import ContactPage from "./Components/ContactPage";
import React, { useState } from "react";
import styles from "./css/HomePage.module.css";
import Blog from "./Components/Articles/Blog";
import SinglePost from "./Components/Articles/SinglePost";

function App() {
    const [isContactPopupVisible, setContactPopupVisible] = useState(false);

    const toggleContactPopup = () => {
        setContactPopupVisible(!isContactPopupVisible);
    };

    return (
        <div className="App">
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/en" element={<En />} />
                <Route exact path="/products" element={<LandingPage />} />
                <Route exact path="/uredski-pomocnik" element={<OfficeAssistant />} />
                <Route exact path="/itcs-oa/privacy_policy" element={<Privacy />} />
                <Route exact path="/contact" element={<ContactPage />} />
                <Route exact path="/articles" element={<Blog />} />
                <Route path="/post/:id" element={<SinglePost />} />

                {/* Catch-all ruta za preusmjeravanje na homepage */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>

            {/* Floating button */}
            <div className={styles.floatingButton} onClick={toggleContactPopup}>
                <span className={styles.icon}>📞</span>
            </div>

            {/* Overlay and contact popup */}
            {isContactPopupVisible && (
                <>
                    <div className={styles.overlay} onClick={toggleContactPopup}></div>
                    <div className={styles.contactPopup}>
                        <h3>Kontaktirajte nas</h3>
                        <p><strong>Email:</strong> <a href="mailto:info@itcs.hr">info@itcs.hr</a></p>
                        <p><strong>Telefon:</strong> <a href="tel:+385911280108">+385 91 128 0108</a></p>
                        <p><strong>Adresa:</strong> Mekušanska cesta 5, 47 000 Karlovac, Hrvatska</p>
                        <button onClick={toggleContactPopup} className={styles.closeButton}>Zatvori</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default App;
